import React, { FunctionComponent } from 'react';
import { ISolutionTab } from '../../../@types/generated/contentful';
import RichText from '../../richText';
import Video from '../../video';
import dynamic from 'next/dynamic';

type Props = {
	content: ISolutionTab
};

const CallToAction = dynamic(() => import('../../callToAction'), {
    ssr: false,
  })

const SolutionTab: FunctionComponent<Props> = ({ content }) => {
    const { label, title, description, ctaButton, video, valuePropositions } = content.fields;

    const btnCTA = ctaButton
        ? <CallToAction key={ctaButton.sys.id} content={ctaButton.fields} callback={() => {}}/>
        : <></>;

    return (
		<div className="flex flex-col md:flex-row w-full px-0 py-6 md:p-12 lg:p-20 bg-white md:bg-sp-light-purple">
			<div className="flex-1 flex flex-col">
				<span className="seo-copy text-sp-dark-grey">{label.toUpperCase()}</span>
				<h2 className="mt-1">{title}</h2>
				{description ? (
					<span className="body-text mt-6">{description}</span>
				) : ''}
				<div className="mt-6">
					{ btnCTA }
				</div>
                {video ? (
					<Video content={video} />
                ) : ''}
			</div>
			{valuePropositions ? (
				<div className="flex-1 mt-8 ml-0 md:mt-0 md:ml-6">
					<RichText content={valuePropositions}/>
				</div>
			) : ''}
		</div>
    );
};

export default SolutionTab;
